import { ConsultQueue, ConsultQueueInteractionModeEnum } from "../types/consult-api";

const getModalityType = (consult: ConsultQueue) => {
    switch (consult.interactionMode) {
        case ConsultQueueInteractionModeEnum.ChartReview:
            return {
                label: "Chart Review",
                icon: "Clipboard",
            };
        case ConsultQueueInteractionModeEnum.Chat:
            return {
                label: "Live Chart",
                icon: "Chats",
            };
        case ConsultQueueInteractionModeEnum.Message:
            return {
                label: "Message",
                icon: "ChatText",
            };
        case ConsultQueueInteractionModeEnum.PhoneCall:
            return {
                label: "Phone Call",
                icon: "Phone",
            };
        case ConsultQueueInteractionModeEnum.VideoChat:
            return {
                label: "Video Call",
                icon: "VideoCamera",
            };
        default:
            return { label: "Icon Not Specified", icon: "X" };
    }
};

export default getModalityType;
