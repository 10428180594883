import moment from "moment";
import { Encounter, Episode } from "../types/emr-api";

const hasNewNotes = (episode: Episode, encounters: Array<Encounter>) => {
    if (!episode) {
        return false;
    }
    if (episode.careTeamNotes.length === 0) {
        return false;
    }

    // If there are no past encounters, consider the notes to be new
    if (encounters.length === 0) {
        return true;
    }

    const newestCommentCreatedAt = moment(episode.careTeamNotes[0].createdAt, "YYYY-MM-DD HH:mm:ss");
    const oldestEncounterCompletedAt = moment(encounters[0].completedAt);

    return newestCommentCreatedAt > oldestEncounterCompletedAt;
};

export default hasNewNotes;
