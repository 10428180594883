import debounce from "lodash/debounce";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useCallback, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Annotation, Reason, ReasonStatusEnum } from "../../types/consult-api";
import { Annotations } from "./Annotations";
import { ConsultModalDialogEnum } from "./ConsultModals";

// Check for any TextExpander snippets that didn't get expanded
const TEXT_EXPANDER_REGEX = /(^|\s)\.[a-zA-Z0-9]+\b/;

export function PatientInstructions(props: {
    value: string;
    onChange: (value: string) => void;
    annotations: Annotation[];
    isEditDisabled?: boolean;
    reason?: Reason;
    type?: ConsultModalDialogEnum;
    otherPartnerNames?: string[];
    hasError: (hasError: boolean) => void;
}) {
    const isRejected = props.reason?.status === ReasonStatusEnum.Rejected || !props.reason;
    const isDisabled = props.type === ConsultModalDialogEnum.CannotProvideCare && isRejected;

    const otherPartnerNames = props.otherPartnerNames || [];
    const [errorMessage, setErrorMessage] = useState("");

    const validateText = (text: string) => {
        const errors: string[] = [];

        for (const partnerName of otherPartnerNames) {
            if (text.includes(partnerName)) {
                errors.push(`Treatment Plan contains an incorrect partner name (${partnerName}).`);
            }
        }
        if (TEXT_EXPANDER_REGEX.test(text)) {
            errors.push("Treatment Plan contains a TextExpander snippet.");
        }
        if (errors.length > 0) {
            throw new Error(errors.join(" "));
        }
    };

    // Performance improvement: Wait a half second after the last keystroke before checking for other partner names
    const debouncedValidate = useCallback(
        debounce((text: string) => {
            try {
                validateText(text);
                setErrorMessage("");
                props.hasError(false);
            } catch (err: any) {
                setErrorMessage(err.message);
                props.hasError(true);
            }
        }, 500),
        [otherPartnerNames, props]
    );

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newText = e.target.value;
        debouncedValidate(newText);
        props.onChange(newText);
    };

    return (
        <div>
            <p className="text-base font-semibold mt-6">Treatment Plan {!props.isEditDisabled && !isDisabled && <span style={{ color: "red" }}>*</span>}</p>
            {props.isEditDisabled ? (
                <div className="text-sm font-normal">
                    {props.value ? (
                        <ReactMarkdown className="markdown">{props.value}</ReactMarkdown>
                    ) : (
                        <span style={{ color: "gray" }}>No Treatment Plan was entered.</span>
                    )}
                </div>
            ) : (
                <InputTextarea
                    value={props.value}
                    onChange={handleChange}
                    placeholder={"Add treatment plan"}
                    rows={20}
                    className="w-full"
                    style={{ fontSize: "14px" }}
                    disabled={isDisabled}
                />
            )}
            {props.isEditDisabled ? (
                <></>
            ) : (
                <>
                    {errorMessage && <div style={{ color: "red", marginTop: "0.5rem" }}>{errorMessage}</div>}
                    {props.annotations.length > 0 && <p className="text-sm">TextExpander: {<Annotations value={props.annotations} />}</p>}
                    <p className="text-sm" style={{ color: "gray" }}>
                        Treatment Plan is automatically sent to the patient when the consult is completed.
                    </p>
                </>
            )}
        </div>
    );
}
