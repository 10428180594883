const dayOptsWithWeekday: Intl.DateTimeFormatOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
};

const dayOptsNoWeekday: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
};

const timeOpts: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
};

const getTimeDifference = (messageSentAt: string) => {
    const currentDateTime = new Date();
    const messageISODate = new Date(messageSentAt);
    const messageDateTime = messageISODate.getTime();
    const ms = currentDateTime.getTime() - messageDateTime;
    return {
        seconds: Math.floor(ms / 1000),
        minutes: Math.floor(ms / (1000 * 60)),
        hours: Math.floor(ms / (1000 * 60 * 60)),
        days: Math.floor(ms / (1000 * 60 * 60 * 24)),
        messageISODate,
        currentDateTime,
    };
};

const formatTime = (date: Date) => date.toLocaleTimeString("en-US", timeOpts);

const formatDate = (date: Date, options: Intl.DateTimeFormatOptions) => date.toLocaleDateString("en-US", options);

const getMessageTimeAgo = (messageSentAt: string) => {
    const { seconds, minutes, hours, days, messageISODate, currentDateTime } = getTimeDifference(messageSentAt);
    const currentDay = currentDateTime.getDate();
    const messageDay = messageISODate.getDate();
    const yesterday = currentDay - 1;

    if (seconds <= 59) {
        return "Just now";
    }
    if (minutes === 1) {
        return minutes + " min ago";
    }
    if (minutes < 60) {
        return minutes + " mins ago";
    }
    if (hours === 1) {
        return hours + " hour ago";
    }
    if (hours < 24) {
        return hours + " hours ago";
    }
    if (days === 1 && messageDay === yesterday) {
        return `Yesterday at ${messageISODate.toLocaleTimeString("en-US", timeOpts)}`;
    }
    if (days >= 2 && days <= 6) {
        return `${messageISODate.toLocaleDateString("en-US", { weekday: "long" })} at ${messageISODate.toLocaleTimeString("en-US", timeOpts)}`;
    }
    if (days > 6 && days < 365) {
        return `${messageISODate.toLocaleDateString("en-US", dayOptsWithWeekday)} at ${messageISODate.toLocaleTimeString("en-US", timeOpts)}`;
    }
    if (days >= 365) {
        return `${messageISODate.toLocaleDateString("en-US", dayOptsNoWeekday)} at ${messageISODate.toLocaleTimeString("en-US", timeOpts)}`;
    }
    return messageISODate.toLocaleDateString("en-US", Object.assign(dayOptsWithWeekday, timeOpts));
};

const getBroadcastTimeAgo = (messageSentAt: string) => {
    const { days, messageISODate, currentDateTime } = getTimeDifference(messageSentAt);
    const currentDay = currentDateTime.getDate();
    const messageDay = messageISODate.getDate();
    const yesterday = currentDay - 1;

    if (days === 0 && messageDay != yesterday) {
        // Show time only if the message is from today
        return formatTime(messageISODate);
    }
    if (messageDay === yesterday) {
        // Show "Yesterday" if the message is from yesterday
        return "Yesterday";
    }
    // Show "M/D/YY" for older messages
    return formatDate(messageISODate, { month: "numeric", day: "numeric", year: "2-digit" });
};

export { getMessageTimeAgo, getBroadcastTimeAgo };
