import * as Sentry from "@sentry/browser";
import { AxiosError, AxiosResponse } from "axios";
import { ClinicianApi } from "../types/clinician-api";
import { ConsultReasonForVisitEnum, PaginatedConsultQueueList, Program } from "../types/consult-api";

const handleError = (err: AxiosError | any, consultGuid?: string[]) => {
    let errorMessage = err.response?.data?.message || err.message || "An unexpected error occurred. Please try again later.";
    if (consultGuid && consultGuid.length > 0) {
        errorMessage = "Unavailable Consult";
    }
    throw new Error(errorMessage);
};

type reasonTypes =
    | "initial"
    | "follow_up"
    | "side_effect"
    | "pharmacy_change"
    | "medication_change"
    | "additional_documentation"
    | "adhoc_follow_up"
    | "adhoc_side_effect"
    | "adhoc_pharmacy_change"
    | "adhoc_pharmacy_call"
    | "adhoc_medication_change"
    | "adhoc_additional_documentation"
    | "lab_result";

export async function getMyConsults(
    consultGuid?: string[],
    partnerGuid?: string[],
    programGuid?: string[],
    reasonForVisit?: ConsultReasonForVisitEnum[],
    asyncConsults?: boolean,
    minScore?: number,
    maxScore?: number
): Promise<PaginatedConsultQueueList> {
    const client = new ClinicianApi();
    try {
        const reasons = reasonForVisit as reasonTypes[];
        const response = (await client.consultQueue.consultQueueGetAssignedConsultsRetrieve(
            consultGuid,
            maxScore,
            minScore,
            asyncConsults,
            partnerGuid,
            programGuid,
            reasons
        )) as any;
        return response.data;
    } catch (err: AxiosError | any) {
        Sentry.captureException(err);
        handleError(err, consultGuid);
    }
    return { next: "", previous: "", results: [] };
}

export async function getOtherConsults(
    consultGuid?: string[],
    partnerGuid?: string[],
    programGuid?: string[],
    reasonForVisit?: ConsultReasonForVisitEnum[],
    asyncConsults?: boolean,
    difficultyScoreMin?: number,
    difficultyScoreMax?: number
): Promise<PaginatedConsultQueueList> {
    const client = new ClinicianApi();
    try {
        const reasons = reasonForVisit as reasonTypes[];
        const response = (await client.consultQueue.consultQueueGetAvailableConsultsRetrieve(
            consultGuid,
            difficultyScoreMax,
            difficultyScoreMin,
            asyncConsults,
            partnerGuid,
            programGuid,
            reasons
        )) as any;
        return response.data;
    } catch (err: AxiosError | any) {
        Sentry.captureException(err);
        handleError(err, consultGuid);
    }
    return { next: "", previous: "", results: [] };
}

export async function getMaConsults(
    consultGuid?: string[],
    partnerGuid?: string[],
    programGuid?: string[],
    reasonForVisit?: ConsultReasonForVisitEnum[],
    asyncConsults?: boolean,
    difficultyScoreMin?: number,
    difficultyScoreMax?: number
): Promise<PaginatedConsultQueueList> {
    const client = new ClinicianApi();
    const reasons = reasonForVisit as reasonTypes[];
    try {
        const response = (await client.consultQueue.consultQueueGetMaConsultsRetrieve(
            consultGuid,
            difficultyScoreMax,
            difficultyScoreMin,
            asyncConsults,
            partnerGuid,
            programGuid,
            reasons
        )) as any;
        return response.data;
    } catch (err: AxiosError | any) {
        Sentry.captureException(err);
        handleError(err);
    }
    return { next: "", previous: "", results: [] };
}

export async function getClinicianMaConsults(): Promise<PaginatedConsultQueueList> {
    const client = new ClinicianApi();
    try {
        const response = (await client.consultQueue.consultQueueGetClinicianOpenMaConsultsRetrieve()) as any;
        return response.data;
    } catch (err: AxiosError | any) {
        Sentry.captureException(err);
        handleError(err);
    }
    return { next: "", previous: "", results: [] };
}

export async function getNextUpConsults(
    consultGuid?: string[],
    partnerGuid?: string[],
    programGuid?: string[],
    reasonForVisit?: ConsultReasonForVisitEnum[],
    asyncConsults?: boolean,
    difficultyScoreMin?: number,
    difficultyScoreMax?: number
): Promise<PaginatedConsultQueueList> {
    const client = new ClinicianApi();
    try {
        const reasons = reasonForVisit as reasonTypes[];
        const response = (await client.consultQueue.consultQueueGetNextUpConsultsRetrieve(
            consultGuid,
            difficultyScoreMax,
            difficultyScoreMin,
            asyncConsults,
            partnerGuid,
            programGuid,
            reasons
        )) as any;
        return response.data;
    } catch (err: AxiosError | any) {
        Sentry.captureException(err);
        handleError(err, consultGuid);
    }
    return { next: "", previous: "", results: [] };
}

export async function getNextUpScheduledConsults(): Promise<PaginatedConsultQueueList> {
    const client = new ClinicianApi();
    try {
        const response = (await client.consultQueue.consultQueueGetNextUpScheduledConsultsRetrieve()) as any;
        return response.data;
    } catch (err: AxiosError | any) {
        Sentry.captureException(err);
    }
    return { next: "", previous: "", results: [] };
}

export async function getCompletedConsults(): Promise<PaginatedConsultQueueList> {
    const client = new ClinicianApi();
    try {
        const response = (await client.consultQueue.consultQueueGetCompletedConsultsRetrieve()) as any;
        return response.data;
    } catch (err: AxiosError | any) {
        Sentry.captureException(err);
    }
    return { next: "", previous: "", results: [] };
}

export async function getMyPrograms(): Promise<Program[]> {
    const client = new ClinicianApi();
    try {
        const response = (await client.program.programList()) as AxiosResponse<Program[]>;
        return response.data;
    } catch (err: AxiosError | any) {
        Sentry.captureException(err);
    }
    return [];
}
