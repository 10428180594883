import { ConsultQueue, ConsultQueueReasonForVisitEnum } from "../types/consult-api";

const getVisitReason = (consult: ConsultQueue) => {
    switch (consult.reasonForVisit) {
        case ConsultQueueReasonForVisitEnum.Initial:
            return "Initial";
        case ConsultQueueReasonForVisitEnum.FollowUp:
        case ConsultQueueReasonForVisitEnum.AdhocFollowUp:
            return "Follow Up";
        case ConsultQueueReasonForVisitEnum.SideEffect:
        case ConsultQueueReasonForVisitEnum.AdhocSideEffect:
            return "Side Effect";
        case ConsultQueueReasonForVisitEnum.PharmacyChange:
        case ConsultQueueReasonForVisitEnum.AdhocPharmacyChange:
            return "Pharmacy Change";
        case ConsultQueueReasonForVisitEnum.MedicationChange:
        case ConsultQueueReasonForVisitEnum.AdhocMedicationChange:
            return "Medication Change";
        case ConsultQueueReasonForVisitEnum.AdditionalDocumentation:
        case ConsultQueueReasonForVisitEnum.AdhocAdditionalDocumentation:
            return "Additional Documentation";
        case ConsultQueueReasonForVisitEnum.AdhocPharmacyCall:
            return "Pharmacy Call";
        case ConsultQueueReasonForVisitEnum.LabResult:
            return "Lab Result";
        default:
            return "";
    }
};

export default getVisitReason;
