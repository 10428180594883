import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";
import { useSession } from "../../functions/useSession";
import { AsyncOnlyReasonModalProps, FormattedDuration } from "../../types/components/queue/asyncOnlyReasonModalProps";
import { AsyncOnlyFilterReasons } from "../../types/consult-api";
import { renderDialog } from "../consult/ConsultModals";

export interface QueueModalProps {
    isVisible: boolean;
    cancelFn: (props?: any) => void;
    completeFn: (props?: any) => void;
}

export function AcceptSyncVideoConsultModal({ isVisible, cancelFn, completeFn }: QueueModalProps) {
    const style = { maxWidth: "516px" };
    const header = "Are you camera-ready?";
    const cancel = "No, take me back";
    const complete = "Yes, I'm ready";
    const children = (
        <span>
            <p className="text-sm font-normal" style={{ marginTop: "0", marginBottom: "24px", lineHeight: "24px" }}>
                This is a sync consult where you will need to be <strong>on camera</strong>.<br />
                The patient will be notified that the consult is starting.
            </p>
            <img src="/are-you-camera-ready.svg" alt="Are You Camera Ready?" style={{ width: "100%" }} />
        </span>
    );
    return renderDialog({
        isVisible,
        style,
        header,
        cancel,
        cancelFn,
        complete,
        completeFn,
        children,
        resizable: false,
    });
}

export function AcceptSyncPhoneConsultModal({ isVisible, cancelFn, completeFn }: QueueModalProps) {
    const style = { maxWidth: "516px" };
    const header = "Are you ready to provide care?";
    const cancel = "No, take me back";
    const complete = "Yes, I'm ready";
    const children = (
        <span>
            <p className="text-sm font-normal" style={{ marginTop: "0", marginBottom: "24px", lineHeight: "24px" }}>
                Accepting this sync consult will notify the patient that their consult is starting.
            </p>
            <img src="/consult-is-starting.svg" alt="Consult Is Starting" style={{ width: "100%" }} />
        </span>
    );
    return renderDialog({
        isVisible,
        style,
        header,
        cancel,
        cancelFn,
        complete,
        completeFn,
        children,
        resizable: false,
    });
}

export function AsyncOnlyReasonModal({ isVisible, cancelFn, completeFn, methods }: AsyncOnlyReasonModalProps) {
    const [session] = useSession();
    const sessionUser = session?.user as any;

    const style = { maxWidth: "516px" };
    const header = "Please select a reason";
    const cancel = "Cancel";
    const complete = "Filter by Async Consults Only";
    const [reasons, setReasons] = useState<Array<AsyncOnlyFilterReasons>>([]);
    const [durations, setDurations] = useState<FormattedDuration[]>([]);
    const [selectedReason, setSelectedReason] = useState("");
    const [selectedDuration, setSelectedDuration] = useState("");
    const [customReason, setCustomReason] = useState("");

    useEffect(() => {
        const getReasonsAndDurations = async () => {
            const data = await methods.getAsyncOnlyFilterData();
            setReasons(data.reasons);
            setDurations(data.durations);
        };
        getReasonsAndDurations();
    }, [methods]);

    const children = (
        <span>
            <p className="text-sm font-normal" style={{ marginTop: "0", marginBottom: "24px", lineHeight: "24px" }}>
                Please select a reason for why you are filtering by Async consults only.<span style={{ color: "red" }}>*</span>
            </p>
            {reasons.map((reason, index) => {
                return (
                    <div key={index} className="field-radiobutton">
                        <RadioButton
                            name="category"
                            value={reason.reasonLabel}
                            onChange={(e) => setSelectedReason(e.value)}
                            checked={selectedReason === reason.reasonLabel}
                        />
                        <label className={"text-sm font-normal"}>{reason.reasonLabel}</label>
                    </div>
                );
            })}
            <div className="field-radiobutton">
                <RadioButton name="category" value="custom" onChange={(e) => setSelectedReason(e.value)} checked={selectedReason === "custom"} />
                <label style={{ flexGrow: 1 }}>
                    <InputText
                        style={{ width: "100%" }}
                        placeholder="Other: Enter your reason"
                        value={customReason}
                        onChange={(e) => setCustomReason(e.target.value)}
                        disabled={selectedReason !== "custom"}
                    />
                </label>
            </div>
            {!sessionUser?.clinician.currentShift && (
                <Dropdown
                    style={{ width: "100%", marginTop: "8px" }}
                    value={selectedDuration}
                    onChange={(e) => setSelectedDuration(e.value)}
                    options={durations}
                    optionLabel="time"
                    optionValue="duration"
                    placeholder="Select a duration"
                />
            )}
        </span>
    );
    return renderDialog({
        isVisible,
        style,
        header,
        cancel,
        cancelFn,
        complete,
        completeFn: () =>
            completeFn({
                reason: selectedReason === "custom" ? customReason : selectedReason,
                selectedDuration,
            }),
        children,
        resizable: false,
        isCompleteDisabled: !selectedReason || (selectedReason === "custom" && !customReason),
    });
}
