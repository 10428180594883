import moment from "moment-timezone";
import { ConsultQueue, ConsultQueueVisitStatusEnum } from "../types/consult-api";

const closeToOverdue = moment().add(3, "hours");
const timezone = moment.tz.guess();

export enum ConsultCardPriorityEnum {
    Normal,
    High,
    Highest,
}

const getPriority = (value: ConsultCardPriorityEnum) => {
    switch (value) {
        case ConsultCardPriorityEnum.Normal:
            return {
                symbolColor: "#75B02F",
                text: "",
                textColor: "#404040",
                value,
            };
        case ConsultCardPriorityEnum.High:
            return {
                symbolColor: "#F7931E",
                text: "",
                textColor: "#404040",
                value,
            };
        case ConsultCardPriorityEnum.Highest:
            return {
                symbolColor: "#F44949",
                text: "",
                textColor: "#404040",
                value,
            };
    }
};

const getPriorityByDate = function (appointment: moment.Moment, isDueNow: boolean) {
    if (isDueNow) {
        return getPriority(ConsultCardPriorityEnum.Highest);
    }

    const now = moment();
    const isOverdue = now.isAfter(appointment);
    if (isOverdue) {
        return getPriority(ConsultCardPriorityEnum.Highest);
    }

    const isCloseToOverdue = now < appointment && appointment < closeToOverdue;
    if (isCloseToOverdue) {
        return getPriority(ConsultCardPriorityEnum.High);
    }

    return getPriority(ConsultCardPriorityEnum.Normal);
};

const getConsultCardPriority = function (consult: ConsultQueue, isDueNow: boolean) {
    const startsAt = consult.appointmentScheduledStartsAt ? consult.appointmentScheduledStartsAt : consult.appointmentStartsAt;
    const appointment = moment.utc(startsAt).tz(timezone);

    if (consult.visitStatus === ConsultQueueVisitStatusEnum.Waiting) {
        const priority = getPriority(ConsultCardPriorityEnum.Normal);
        priority.text = "Waiting for Patient Response";
        return priority;
    }

    if (consult.visitStatus === ConsultQueueVisitStatusEnum.ReadyToResume) {
        if (consult.reason === "On hold" && startsAt) {
            const priority = getPriority(ConsultCardPriorityEnum.Highest);
            priority.text = `${appointment.format("ddd MMM D")} at ${appointment.format("h:mm A")}`;
            priority.textColor = "#F44949";
            return priority;
        }

        if (consult.reason === "Patient replied") {
            const dueDate = moment.utc(consult.dueDate).tz(timezone);
            const priority = getPriorityByDate(dueDate, isDueNow);
            priority.text = "Patient Responded";
            return priority;
        }
    }

    if (consult.isHighPriority) {
        return getPriority(ConsultCardPriorityEnum.Highest);
    }

    if (consult.isScheduled) {
        const priority = getPriorityByDate(appointment, false);

        const now = moment();
        if (priority.value === ConsultCardPriorityEnum.Highest) {
            let text;
            const minSince = now.diff(appointment, "minute");
            const hourSince = now.diff(appointment, "hour");
            if (minSince < 1) {
                text = "Started just now";
            } else if (minSince >= 1 && minSince < 60) {
                text = `Started ${minSince}m ago`;
            } else if (hourSince < 24) {
                text = `Started ${hourSince}h ago`;
            } else {
                const daySince = now.diff(appointment, "day");
                text = `Started ${daySince}d ago`;
            }
            priority.text = text;
            return priority;
        }

        let text;
        const todayEndOf = moment().endOf("day");
        const tomorrowEndOf = moment().add(1, "day").endOf("day");
        if (appointment.isAfter(now) && appointment.isBefore(todayEndOf)) {
            text = `Today at ${appointment.format("h:mm A")}`;
        } else if (appointment.isAfter(todayEndOf) && appointment.isBefore(tomorrowEndOf)) {
            text = `Tomorrow at ${appointment.format("h:mm A")}`;
        } else {
            text = `${appointment.format("ddd MMM D")} at ${appointment.format("h:mm A")}`;
        }
        priority.text = text;
        return priority;
    }

    if (consult.dueDate) {
        const dueDate = moment.utc(consult.dueDate).tz(timezone);
        return getPriorityByDate(dueDate, isDueNow);
    }

    return getPriority(ConsultCardPriorityEnum.Normal);
};

export default getConsultCardPriority;
